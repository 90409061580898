<!--语音转文本的组件-->
<!--// 对外广播confirm事件 传递转译的文本-->
<script>
import formSelectWarp from '../common-selector/form-select-wrap'
import { Icon } from 'vant'

let localId = null
let timer = null // 计时器的清除

export default {
  resolve: null,
  reject: null,
  components: {
    formSelectWarp,
    vanIcon: Icon
  },
  data() {
    return {
      showPicker:false,
      time: 0, // 显示记时
      text: '', // 识别的文本
      recording: false // 录音状态
    }
  },
  methods: {
    // 初始化处理数据
    initData() {
      this.text = ''
      this.time = 0
      // 停止计时
      if (!timer) return
      window.clearInterval(timer)
    },
    start() {
      this.recording = true
      window.setTimeout(() => {
        window.wx.startRecord({
          success: () => {
            this.recording = true
            // 全部清零
            this.initData()

            // 开始计时
            timer = window.setInterval(() => this.showTime(), 1000)
            window.wx.onVoiceRecordEnd({
              // 录音时间超过一分钟没有停止的时候会执行 complete 回调
              complete: res => {
                localId = res.localId
                if (timer) {
                  window.clearInterval(timer)
                }
                this.time = 60
                this.translate(localId)
              }
            })
          }
        })

      }, 100)
    },
    end(onSuccess = () => {}) {
      window.wx.stopRecord({
        success: res => {
          localId = res.localId
          this.translate(localId, onSuccess)
        }
      })
      // 停止计时
      if (!timer) return
      window.clearInterval(timer)
    },
    // 声音转文本
    translate(localId, onSuccess) {
      this.recording = false
      window.wx.translateVoice({
        localId, // 需要识别的音频的本地Id，由录音相关接口获得，音频时长不能超过60秒
        isShowProgressTips: 1, // 默认为1，显示进度提示
        success: res => {
          this.text = res.translateResult.trim()
          onSuccess()
        }
      })
    },
    confirm() {
      if (this.recording) {
        this.end(() => {
          this.resolve(this.text)
          this.showPicker = false
        })
        return
      }
      this.resolve(this.text)
      this.showPicker = false
    },
    cancel() {
      // 返回的时候 停止录音 停止计时
      this.end()
      this.reject('cancel')
      this.showPicker = false
    },
    // 定时器
    showTime() {
      this.time += 1
    }

  }
}
</script>
<template lang="pug">
    form-select-warp(:showPicker="showPicker" title="语音输入"
      :multiple="true"
      @cancel="cancel"
      @confirm="confirm")
      template(#content)
        #record-page
          #record-time {{time}}
          p 请注意，微信录音仅支持60秒以内.
          #record-opt
            .opt(v-if="!recording" @click="start")
              van-icon(name="play-circle" size="5rem" color="#EED484")
            .opt(v-if="recording" @click="end")
              van-icon(name="stop-circle" size="5rem" color="#EED484")
          #record-content(v-if="text")
            | {{text}}

</template>
<style lang="less">
.record-text {
  color: @theme-color;
  user-select: none;
}

#record-page {
  text-align: center;

  #record-time {
    font-size: 5rem;
    margin-top: 5rem;
    color: @theme-color;
  }

  p {
    color: #646566;
    font-size: 0.8rem;
  }

  #record-content {
    width: 80%;
    background-color: #f5f5f5;
    margin: 2rem auto;
    text-align: left;
    padding: .5rem;
  }

  #record-opt {
    margin: 3rem 0;
    .opt {
      background-color: white;

    }
  }
}
</style>
