<template>
  <button v-if="judgePc" type="button" class="voice-input" @click="voiceInput">
    <img class="icon-voice" src="./icon_voice.svg" />
    <!-- <span class="text">语音输入</span> -->
  </button>
</template>

<script>
import recordToText from '@/components/record-to-text'
export default {
  data() {
    return {
    }
  },
  computed: {
    judgePc() {
      return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
    }
  },
  mounted() {
    // wx.checkJsApi({
    //   jsApiList: ['startRecord', 'translateVoice'], // 需要检测的JS接口列表
    //   success: ({ checkResult = {} }) => {
    //     if (checkResult.startRecord && checkResult.translateVoice) {
    //       this.show = true
    //     }
    //     // 以键值对的形式返回，可用的api值true，不可用为false
    //     // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
    //   },
    // })
  },
  methods: {
    voiceInput() {
      recordToText()
        .then((text) => {
          this.$emit('input', text)
        })
        .catch(() => {})
    },
  },
}
</script>

<style scoped lang="less">
.voice-input {
	display: flex;
	align-items: center;
	.icon-voice {
		width: 22px;
  }
	.text {
		font-size: 14px;
		color: #3476FE;
		line-height: 17px;
		font-weight: 400;
		margin-left: 8px;
	}
}
</style>
