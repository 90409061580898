<!-- 交车跟进 -->
<template>
<div class="deliverFollow">
  <div class="common-block">
    <div class="deliverInfo">
      <div class="deliverInfo-t">
        <div class="deliverInfo-t-l">
          <div class="deliverInfo-t-l-id">{{ deliverDetail.orderType===1?deliverDetail.dcosOrderId: deliverDetail.appOrderId }}</div>
          <div v-if="[1,0].includes(deliverDetail.installmentsFlag)" class="deliverInfo-t-l-status">{{ deliverDetail.installmentsFlag == 1 ? $t('分期') : $t('全款') }}</div>
        </div>
        <div class="deliverInfo-t-r">
          <div v-if="deliverDetail.carOrderMaterial">{{ `${deliverDetail.carOrderMaterial?.seriesName} ${deliverDetail.carOrderMaterial?.modelName}` }}</div>
          <div v-if="deliverDetail.totalAmount" class="amount"><span content="¥"></span>{{ `${formatRMB(deliverDetail.totalAmount)}` }}</div>
        </div>
      </div>
      <div class="deliverInfo-m">
        <div class="item half">
          <div class="common-label-t">{{$t('车身颜色：')}}</div>
          <div class="common-label-c">{{ deliverDetail.carOrderMaterial?.colourName }}</div>
        </div>
        <div class="item half">
          <div class="common-label-t">{{$t('内饰颜色：')}}</div>
          <div class="common-label-c">{{ deliverDetail.carOrderMaterial?.interiorName }}</div>
        </div>
        <div class="item">
          <div class="common-label-t">{{$t('选装：')}}</div>
          <div class="common-label-c options">
            <div v-for="(optionsItem, index) in (deliverDetail.carOptionsList || [])" :key="index" class="options-t">{{ `${index+1}、${optionsItem.optionsName || ''}` }}</div>
          </div>
        </div>
        <div class="item">
          <div class="common-label-t">VIN：</div>
          <div class="common-label-c">{{ deliverDetail.vin }}</div>
        </div>
      </div>
    </div>
  </div>
  <van-form ref="deliverForm" input-align="right" error-message-align="right" class="common-block">
    <van-cell>
      <template #title>
        <span style="font-size: 15px;font-weight: bold;">{{$t('跟进信息')}}</span>
      </template>
    </van-cell>
    <!-- 13ddbb -->
    <van-field
      name="radio"
      :label="$t('跟进方式')"
      input-align="right"
      error-message-align="right">
      <template #input>
        <van-radio-group
          v-model="followRequest.followMode"
          icon-size="32px"
          class="custom-radio-group-wrap">
          <van-radio v-for="item in followModeList" :key="item.code"  :name="item.code" class="custom-radio-wrap">
            <template #icon="props">
              <div class="custom-radio" :class="{ checked: props.checked, wechatCheck: props.checked }">{{item.name}}</div>
            </template>
          </van-radio>
        </van-radio-group>
      </template>
    </van-field>
    <!-- 跟进结果 -->
    <!-- <van-field
      name="radio"
      label="跟进结果"
      required
      :rules="[{required: true, message:'请选择跟进结果'}]"
      error-message-align="right"
      input-align="right">
      <template #input>
        <van-radio-group
          v-if="followResultList.length"
          v-model="followRequest.followResult"
          icon-size="32px"
          class="custom-radio-group-wrap"
        >
          <van-radio v-for="item in followResultList" :key="item.dictCode" :name="item.dictCode" class="custom-radio-wrap">
            <template #icon="props">
              <div class="custom-radio" :class="{ checked: props.checked, commonCheck: props.checked }" @click.stop="onChangeResult(item)">{{item.dictName}}</div>
            </template>
          </van-radio>
        </van-radio-group>
        <p v-else style="color:#969799;">请优先选择跟进方式</p>
      </template>
    </van-field> -->
    <!-- 71f2cd(待跟进时显示) / 无效原因 / 战败原因-->
    <van-field
      v-if="followRequest.followMode === '1010001'"
      v-model="followRequest.sysRemark"
      :label="$t('联系结果')"
      :placeholder="$t('请选择联系结果')"
      right-icon="arrow"
      readonly
      input-align="right"
      @click="selectField('failCode', 'sysRemark', 1024, false)">
      <template #input>
        <div>
          <div v-if="followRequest.sysRemark">{{ followRequest.sysRemark }}</div>
          <div v-else style="color:#c8c9cc;font-size: 12px;">{{$t('请选择联系结果')}}</div>
        </div>
      </template>
    </van-field>
    <van-field
      :label="$t('交付方式')"
      readonly
      input-align="right">
      <template #input>
        <div>{{ deliverDetail.deliveryMethod | codeFormat}}</div>
      </template>
    </van-field>
    <van-field
      v-if="isShowRemoteAddress"
      :label="$t('客户详细地址')"
      readonly
      input-align="right">
      <template #input>
        <div>{{ remoteDeliverAddress}}</div>
      </template>
    </van-field>
    <!-- f878f6 -->
    <van-field
      v-model="booDeliveryTimeShow"
      :label="$t('预计交车时间')"
      :right-icon="selectDeliverTime && !deliverDetail.bookRes?.bookDate ? 'arrow' : ''"
      readonly
      input-align="right"
      :placeholder="$t('请选择')"
      @click="selectCalendar">
      <template #input>
        <div v-if="!selectDeliverTime">{{$t('暂无法进行交车排程！')}}</div>
      </template>
    </van-field>
    <!-- 是否风险 -->
    <van-field
      :label="$t('是否风险')"
      readonly
      input-align="right">
      <template #input>
        <van-radio-group
          v-model="followRequest.orderLabelShow"
          icon-size="32px"
          class="custom-radio-group-wrap">
          <van-radio v-for="item in riskOption" :key="item.code"  :name="item.code" class="custom-radio-wrap">
            <template #icon="props">
              <div class="custom-radio" :class="{ checked: props.checked, wechatCheck: props.checked }">{{item.name}}</div>
            </template>
          </van-radio>
        </van-radio-group>
      </template>
    </van-field>
    <!-- 品牌保险推荐 -->
    <!-- <van-field
      v-model="followRequest.insureTypeName"
      :label="$t('品牌保险推荐')"
      right-icon="arrow"
      readonly
      input-align="right"
      :placeholder="$t('请选择品牌保险推荐')"
      @click="selectField('insureType', 'insureTypeName', 4010, false)">
    </van-field> -->
    <!-- 5f03df -->
    <van-cell :title="$t('跟进记录')">
      <template #right-icon>
        <btn-voice-input @input="(value) => (followRequest.followRemark = followRequest.followRemark + value.slice(0, 200))" />
      </template>
    </van-cell>
    <van-field
      v-model.trim="followRequest.followRemark"
      rows="3"
      autosize
      type="textarea"
      maxlength="500"
      :placeholder="$t('请输入跟进记录')"
      show-word-limit
    />
    <!-- a0214d -->
    <van-field
      v-model="followRequest.planTime"
      :label="$t('下次跟进时间')"
      :placeholder="$t('请选择下次跟进时间')"
      right-icon="arrow"
      readonly
      input-align="right"
      @click="selecNextFollowTime">
    </van-field>
    <div class="common-footer">
      <van-button class="btn back" native-type="button" @click.stop="goBack">{{ $t('返回') }}</van-button>
      <van-button class="btn submit" type="submit" @click="onSubmit">{{ $t('提交') }}</van-button>
    </div>
  </van-form>
  <van-cell-group v-if="tripDetails.id" inset style="margin-bottom: 16px;">
    <van-cell>
      <template #title>
        <span style="font-size: 15px;font-weight: bold;">{{$t('跟进历史')}}</span>
      </template>
    </van-cell>
    <FollowLog :trip="tripDetails"/>
  </van-cell-group>
  <van-calendar v-model="showNextFollowTime" :first-day-of-week="1" :title="$t('下次跟进时间')" :show-confirm="false" :min-date="planeMinDate" :max-date="planeMaxDate" :default-date="defaultValue" @confirm="dateConfirm"/>
  <van-popup v-model="showCalendar" style="width:100%;max-width: 500px;height:100%">
    <div  class="popupNav">
      <div class="opt" @click="showCalendar=false">
        <slot name="header-left">
          <van-icon name="arrow-left" size="18"></van-icon>
        </slot>
      </div>
      <div class="opt" @click="handleDateTimeConfirm">
        <slot name="header-right"> {{$t('确定')}} </slot>
      </div> 
    </div>
    <calendarDateVue
      v-if="showCalendar"
      ref="calendarDate"
      :isRemove="false"
      :date="booDeliveryTimeRange"
      @change="(time)=>{booDeliveryTimeRange=time}"/>
  </van-popup>
</div>
</template>
<script>
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'
import BtnVoiceInput from '@/components/btn-voice-input'
import commonSelector from '@/components/common-selector'
import { dateFormat, padTime } from '@/utils'
import deliveryServices from '@/services/deliveryServices'
import clueServices from '@/services/clueServices'
import calendarDateVue from '@/modules/appointment/component/calendarDate.vue'
import FollowLog from './component/follow-log.vue'
import loading from '@/utils/loading'
import mixin from './mixin'
export default {
  name: 'deliverFollow',
  components: { BtnVoiceInput, calendarDateVue, FollowLog },
  mixins:[mixin],
  data () {
    return {
      tripDetails: {},
      followRequest: {
        failCode: '',
        followMode: '',
        // followResult: '',
        followRemark: '',
        planTime: '',
        sysRemark: '',
        orderLabel: [],
        orderLabelShow: '',
        insureType: '',
        insureTypeName: '',
        book: {
          bookDate: '',
          bookTimeEnd: '',
          bookTimeStart: '',
          deliveryType: '4007001',
          taskCode: 'DELIVERY_BOOK',
        }
      },
      showNextFollowTime: false,
      planeMinDate: new Date(),
      planeMaxDate: new Date(dayjs().add(3, 'years')),
      defaultValue: null,
      orderId: '',
      deliverDetail: {},
      showCalendar: false,
      booDeliveryTimeRange:[new Date(padTime(dayjs().add(10, 'minute'))), new Date(padTime(dayjs().add(40, 'minute')))],
      riskOption: [
        { code: '4009001', name: this.$t('是') },
        { code: 1, name: this.$t('否') },
      ]
    }
  },
  computed: {
    ...mapGetters(['dictHash', 'userInfo', 'templateClueInfo']),
    followResultList() {
      let list = ((( this.dictHash || {} ) [1011]) || []).map((item) => ({ ...item, dictName: item.name, dictCode: item.code }))
      list = list.filter(({ code }) => ['1011021', '1011022'].includes(code))
      return list
    },
    followModeList() {
      const { $store, followRequest,$route, } = this
      let list =$store.getters.getDictHash(1010)
      list = list.filter(({ code })=> !(['1010003'].includes(code)))
      return list
    },
    booDeliveryTimeShow() {
      const { bookTimeStart, bookTimeEnd } = this.followRequest.book
      return bookTimeStart ? `${dateFormat(bookTimeStart,'YYYY-MM-DD')}  ${dateFormat(bookTimeStart,'HH:mm')}-${dateFormat(bookTimeEnd,'HH:mm')}` : ''
    },
    // 是否可以进行预约交车时间选择(排程)
    selectDeliverTime() {
      const { deliveryStatus } = this.deliverDetail
      return ['4000005', '4000006', '4000007', '4000008', '4000010'].includes(deliveryStatus)
    },
  },
  watch: {
    'followRequest.followMode'(val) {
      this.followRequest.contactResultCode = ''
      this.followRequest.contactResultName = ''
    }
  },
  async mounted() {
    const { id } = this.$route.query
    this.orderId = id
    await this.getDetail()
    this.getTrips()
  },
  methods: {
    // 获取轨迹
    async getTrips(){
      const params = { 
        id: this.deliverDetail.leadId,
        orderId: this.deliverDetail.id,
        type: 'DELIVERY'
      }
      const res = await clueServices.getTrips(params)
      const { trips = [] } = res
      if (trips.length ) {
        this.tripDetails = trips[0]
      }
    },
    // 获取交车详情
    async getDetail() {
      loading.showLoading()
      const res = await deliveryServices.deliveryDetail({ id: this.orderId })
      loading.hideLoading()
      this.deliverDetail = res || {}
      if (this.deliverDetail.bookRes) {
        const { bookTimeEnd, bookTimeStart } = this.deliverDetail.bookRes
        if (bookTimeStart && bookTimeStart.indexOf('-') > -1) this.deliverDetail.bookRes.bookTimeStart = dateFormat(bookTimeStart,'HH:mm')
        if (bookTimeEnd && bookTimeEnd.indexOf('-') > -1) this.deliverDetail.bookRes.bookTimeEnd = dateFormat(bookTimeEnd,'HH:mm')
      }
      const { bookDeliveryTime, bookRes = {}, insureType } = this.deliverDetail
      this.followRequest.insureType = insureType
      this.followRequest.insureTypeName = this.getDictNameByCode(4010, insureType)
      let bookDate = ''
      let bookTimeStart = ''
      let bookTimeEnd = ''
      // if (bookDeliveryTime) {
      //   bookDate = dateFormat(bookDeliveryTime, 'YYYY-MM-DD')
      //   bookTimeStart = dateFormat(bookDeliveryTime, 'HH:mm:ss')
      //   bookTimeEnd = dateFormat(dayjs(bookDeliveryTime).add('30', 'minutes') , 'HH:mm:ss')
      // }
      if (bookRes) {
        bookDate = bookRes.bookDate
        bookTimeStart = bookRes.bookTimeStart
        bookTimeEnd = bookRes.bookTimeEnd
      }
      if (bookDate) {
        this.booDeliveryTimeRange = [`${bookDate} ${bookTimeStart}`, `${bookDate} ${bookTimeEnd}`]
        this.followRequest.book.bookDate = bookDate
        this.followRequest.book.bookTimeStart = dateFormat(`${bookDate} ${bookTimeStart}`,'YYYY-MM-DD HH:mm:ss')
        this.followRequest.book.bookTimeEnd = dateFormat(`${bookDate} ${bookTimeEnd}`,'YYYY-MM-DD HH:mm:ss')
      }
    },
    onChangeResult({ dictCode }) {
      this.followRequest.followResult = dictCode
    },
    async selectField(field, name, dictType, multiple = false) {
      const _obj = {
        field,
        dictType,
        multiple,
        lastSelect: this.followRequest[field]
      }
      commonSelector(_obj).then(res => {
        this.followRequest[field] = res.dictCode
        if (dictType == 4009) {
          this.followRequest[field] = res.map(({ dictCode }) => { return Number(dictCode) })
          this.followRequest[name] = res.map(({ dictName }) => { return dictName }).join('、')
          return
        }
        this.followRequest[name] = res.dictName
      })
    },
    selecNextFollowTime() {
      this.showNextFollowTime = true
    },
    dateConfirm(date) {
      this.followRequest.planTime = dateFormat(date,'YYYY-MM-DD')
      this.showNextFollowTime = false
    },
    goBack() {
      this.$router.go(-1)
    },
    // 选择预约交车时间
    selectCalendar() {
      if (!['4000005', '4000006'].includes(this.deliverDetail.deliveryStatus) || !this.selectDeliverTime || this.deliverDetail.bookRes?.bookDate) return false
      this.showCalendar = true
    },
    handleDateTimeConfirm(){
      const [start,end] = this.booDeliveryTimeRange
      if (!start && !end){
        this.$toast(this.$t('请选择时间'))
        return
      }
      if (dayjs(end).isBefore(dayjs(start),'minute') || dayjs(end).isSame(dayjs(start),'minute')){
        this.$toast(this.$t('请确认，结束时间要大于开始时间'))
        return
      }
      this.followRequest.book.bookDate = dateFormat(start,'YYYY-MM-DD')
      this.followRequest.book.bookTimeStart = dateFormat(start,'YYYY-MM-DD HH:mm')
      this.followRequest.book.bookTimeEnd = dateFormat(end,'YYYY-MM-DD HH:mm')
      this.showCalendar = false
    },
    async onSubmit() {
      const {
        followMode,
        followResult,
        followRemark,
        planTime,
        failCode,
        sysRemark,
        orderLabel,
        insureType,
        book: {
          bookDate,
          bookTimeEnd,
          bookTimeStart,
          deliveryType,
          taskCode,
        },
      } = this.followRequest
      const params = {
        failCode,
        followMode,
        followRemark,
        followResult,
        orderId: this.orderId,
        planTime: dateFormat(planTime, 'YYYY-MM-DD 23:59:59'),
        sysRemark,
        orderLabel: this.followRequest.orderLabelShow==='4009001'? ['4009001']: [],
        insureType,
        book: {
          bookDate,
          bookTimeEnd: dateFormat(bookTimeEnd,'HH:mm'),
          bookTimeStart: dateFormat(bookTimeStart,'HH:mm'),
          deliveryType,
          taskCode,
        },
      }
      this.$refs.deliverForm.validate().then(async ()=> {
        loading.showLoading()
        const res = await deliveryServices.deliveryFollow(params)
        loading.hideLoading()
        this.$toast.success(this.$t('操作成功！'))
        setTimeout(() => {
          this.$router.go(-1)
        }, 1500)
      })
    }
  }
}
</script>
<style lang="less" scoped>
.deliverFollow{
  padding: 16px 0 0;
  padding-bottom: 96px;
  .popupNav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 46px;
    border-bottom: #f5f5f5 solid 1px;
    color: #0D171A;
    background: #EED484;
    box-sizing: border-box;
    padding:0 16px;
  }
  .common-block{
    overflow: hidden;
    width: calc(100% - 32px);
    margin: 0 auto;
    margin-bottom: 16px;
  }
  /deep/ .custom-radio-group-wrap {
    margin-top: -10px;
    display: flex;
    flex-wrap: wrap;
    .custom-radio-wrap {
      margin-top: 10px;
      margin-left: 12px;
      .custom-radio {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 8px;
        height: 24px;
        color: rgba(13,23,26,0.45);
        background-color: #F5F5F5;
        border-radius: 3px;
        font-size: 12px;
        &.wechatCheck{
          color: #316EE2;
          background: #F2F3FF;
        }
        &.appointmentTypeCheck{
          color: #029640;
          background: rgba(2,150,64,0.1);
        }
        &.typeCheck{
          color: #0082EF;
          background: rgba(0,130,239,0.1);
        }
        &.commonCheck{
          color: #B9921A;
          background: rgba(238,212,132,0.45);
        }
        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
          background-color: #ebedf0;
          border-color: #c8c9cc
        }
      }
    }
  }
  /deep/ .van-cell__title{
    font-size: 14px;
    color: @black;
  }
  .deliverInfo{
    padding: 12px;
    .deliverInfo-t{
      display: flex;
      flex-direction: column;
      height: 52px;
      font-size: 16px;
      .deliverInfo-t-l{
        display: flex;
        height: 16px;
        line-height: 16px;
        margin-bottom: 8px;
        .deliverInfo-t-l-id{
          max-width: calc(100% - 48px);
          // flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .deliverInfo-t-l-status{
          width: 40px;
          margin-left: 8px;
          padding-left: 8px;
          position: relative;
          color: #B9921A;
          box-sizing: border-box;
        }
        .deliverInfo-t-l-status::before{
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          width: 1px;
          height: 70%;
          background-color: #D9D9D9;
          transform: translateY(-50%);
        }
      }
      .deliverInfo-t-r{
        display: flex;
        justify-content: space-between;
        color: #0D171A;
        line-height: 16px;
        font-size: 13px;
        .amount{
          color: #E4002C;
        }
      }
    }
    .deliverInfo-m{
      display: flex;
      flex-wrap: wrap;
      border-top: 1px solid rgba(13,23,26,0.05);
      .item{
        display: flex;
        width: 100%;
        &:not(:last-of-type){
          margin-bottom: 8px;
        }
        &.half{
          width: 50%;
          margin-top: 12px;
        }
        .item-d{
          width: 85px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 12px;
          color: rgba(13,23,26,0.45);
        }
      }
    }
    .deliverInfo-b{
      display: flex;
    }
  }
}
</style>
