<!--
* @Author: tianzl
* @Date: 2023-12-11 17:31:08
* @LastEditors: tianzl
* @LastEditTime: 2023-12-11 17:31:08
* @Description: 交车-交付跟进
 -->
<template>
  <div class="log-item">
    <div>
      <span>{{ getdectText(trip, "eventTypeCode", $store, "1022") }}</span>
      <span style="width:20px;text-align: center;">|</span>
      <span>{{ trip.followUserName }}</span>
    </div>
    <div v-if="trip.followMode">
      <span class="label m-r-20">{{ $t("跟进方式") }}</span>
      <span>
        {{ getdectText(trip, "followMode", $store, "1010") }}
      </span>
    </div>
    <div v-if="trip.sysRemark">
      <span class="label m-r-20">{{ $t("联系结果") }}</span>
      <span>
        {{ trip.sysRemark }}
      </span>
    </div>
    <div v-if="trip.followRemark">
      <span class="label m-r-20">{{ $t("跟进记录") }}</span>
      <span style="flex:1">{{ trip.followRemark }}</span>
    </div>
    <div>
      <span class="label m-r-20">{{ $t("跟进时间") }}</span>
      <span style="flex:1">{{ trip.followTime }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    trip: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    getdectText(trip, field, store, dictCode) {
      const dictHash = store.getters.dictHash
      const list = dictHash[dictCode] || []
      const theOne = list.find((item) => item.code === trip[field])
      return theOne?.name || ''
    },
  },
}
</script>
<style scoped lang="less">
.log-item {
  padding: 0 16px;
  > div {
    margin-bottom: 8px;
    display: flex;
  }
  .label {
    color: rgba(13, 23, 26, 0.45);
  }
  .m-r-20 {
    margin-right: 20px;
  }
}
</style>
