import { render, staticRenderFns } from "./deliverFollow.vue?vue&type=template&id=4cdd5982&scoped=true"
import script from "./deliverFollow.vue?vue&type=script&lang=js"
export * from "./deliverFollow.vue?vue&type=script&lang=js"
import style0 from "./deliverFollow.vue?vue&type=style&index=0&id=4cdd5982&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cdd5982",
  null
  
)

export default component.exports